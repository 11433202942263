<template>
  <div>
    <div class="title flexB">
      <h1>{{ $t("station-detail-title") }}</h1>
    </div>
    <div class="contents register">
      <div class="mainBox">
        <div class="flexL">
          <p class="bold">{{ $t("station-data-serial-num") }}<span>*</span></p>
          <p>YT04564GCCK67R0B0HSP</p>
        </div>

        <div class="flexL" style="margin-bottom: 4rem;">
          <p class="bold">{{ $t("station-data-drone-serial-num") }}<span>*</span></p>
          <p>4GCCK67R0B0HSP</p>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-stationName") }}<span>*</span></p>
          <input type="text" style="width:50rem;"/>
          <input type="number" style="width:10rem; margin-left:10px;"/>
          <span style="margin-left: 5px;">번</span>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-droneName") }}<span>*</span></p>
          <input type="text" style="width:50rem;"/>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-droneModel") }}<span>*</span></p>
          <p>DJI Mavic 3 Pro</p>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-managerSite") }}<span>*</span></p>
          <div style="display: flex;flex-direction: column;">
            <input type="text" class="modalInput" style="width:50rem;" @click="openStationSite" v-model="selectedSite" readonly/>
            <span style="color:#ff3838;">현장 선택 시 사용자 선택이 가능해집니다.</span>
          </div>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-managerUser") }}<span>*</span></p>
          <input type="text" class="modalInput" style="width:50rem;" @click="openStationUser" :value="selectedUser.name" readonly/>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-dept") }}<span>*</span></p>
          <p v-if="selectedUser.affiliation">{{ selectedUser.affiliation }}</p> <!-- 사용자 선택 시 소속 표출 -->
          <p v-else>-</p>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-linkDate") }}<span>*</span></p>
          <p>2024.09.05 11:23:43</p>
        </div>

        <div class="buttonWrap">
          <div>
            <button class="point large">{{ $t("btn-confirm") }}</button>
            <button class="large" @click="$router.go(-1)">{{ $t("btn-cancel") }}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->
    <div v-if="showStationSiteModal" class="modal-mask">
      <div class="modal-container">
        <div class="modal-title">
            <h3>관리현장 선택</h3>
            <button @click="closeStationSiteModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon" style="width: 12px;" /></button>
        </div>
        <div class="modal-input">
          <div class="modal-search">
            <input type="text" style="width: 430px;">
            <div class="buttonWrap">
              <button>검색</button>
              <button>초기화</button>
            </div>
          </div>
        </div>
        <div class="modal-content">
          <div class="modal-list-title">
            <h4>{{ $t("top-select-site-view-list") }}</h4>
            <h5><span>{{ siteList.length }}</span>곳</h5>
          </div>
          <ul class="modal-list">
            <li v-for="(site, index) in siteList" :key="index" @click="selectSite(site)">
              <h3>{{ site.name }}</h3>
              <h4>
                <span>{{ site.address }}</span>
                <span>│</span>
                <span>{{ site.affiliation }}</span>
              </h4>
            </li>
          </ul>
        </div>
        <!-- <div class="modal-btn">
          <button class="btn-save">확인</button>
          <button @click="closeStationSiteModal">닫기</button>
        </div> -->
      </div>
    </div>

    <!-- user modal -->
    <div v-if="showStationUserModal" class="modal-mask">
      <div class="modal-container">
        <div class="modal-title">
            <h3>관리유저 선택</h3>
            <button @click="closeStationUserModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon" style="width: 12px;" /></button>
        </div>
        <div class="modal-input">
          <div class="modal-search">
            <input type="text" style="width: 430px;">
            <div class="buttonWrap">
              <button>검색</button>
              <button>초기화</button>
            </div>
          </div>
        </div>
        <div class="modal-content">
          <div class="modal-list-title">
            <h4>{{ $t("top-select-user-view-list") }}</h4>
            <h5><span>{{ userList.length }}</span>명</h5>
          </div>
          <ul class="modal-list">
            <li v-for="(user, index) in userList" :key="index" @click="selectUser(user)">
              <h3>{{ user.name }}</h3>
              <h4>
                <span>{{ user.affiliation }}</span>
                <span>│</span>
                <span>{{ user.email }}</span>
              </h4>
            </li>
          </ul>
        </div>
        <!-- <div class="modal-btn">
          <button class="btn-save">확인</button>
          <button @click="closeStationSiteModal">닫기</button>
        </div> -->
      </div>
    </div>
  </div>
</template>
<style>
.modalInput:hover{cursor: pointer;background: #eee;}

.modal-mask {position: fixed;z-index: 9998;top: 0;left: 0;width: 100%; height: 100%;background-color: rgba(0, 0, 0, 0.5);display: flex;align-items: center;justify-content: center;}

.modal-wrapper {width: auto;}

.modal-container {background-color: white;padding: 20px;border-radius: 5px;}
.modal-title {display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #ddd;margin-bottom: 15px;padding-bottom: 10px;}
.modal-title>h3 {font-size: 1.6rem;}
.modal-title>button {border: 0;}

.modal-search {display: flex;align-items: center;}
.modal-search>span {color: #969696;background: #eee;padding: 8px 15px;border-radius: 5px;margin-right: 10px;}
.modal-search>select {background: #ffffff url(/img/arrow_down.2fb6f130.svg) no-repeat calc(100% - 5px) center / 20px auto;width: 110px;border: 1px solid #ddd;height: 32px;padding: 5px 25px 5px 12px;border-radius: 5px;font-size: 1.2rem;color: black;}
.modal-search>select>option {background: #ffffff;color: #969696;}
.modal-search>input {margin: 0;background: #ffffff;border: 0;color: #000;border-radius: 5px;border: 1px solid #ddd;}
.buttonWrap {margin: 0; margin-left: 10px;}
.buttonWrap>button {padding: 2px 27px;border: 0;font-weight: 300;color: #fff;border-radius: 5px;}
.buttonWrap>button:nth-child(1) {background: #0080FF;margin-right: 8px;}
.buttonWrap>button:nth-child(2) {background: #fff;color: #969696;border: 1px solid #ddd;}

.modal-content {padding-bottom: 15px;border-bottom: 1px solid #ddd;}
.modal-list-title{    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;}
.modal-list-title > h4{font-size: 1.4rem;}
.modal-list-title > h5{font-size: 1.2rem;}
.modal-list-title > h5 > span{color: #0080ff;}

.modal-list{margin-top: 10px;height: 300px;max-height: 300px;overflow-y: auto;border-top: 1px solid #ddd;}
.modal-list > li{border-bottom: 1px solid #ddd;padding: 15px 10px;}
.modal-list > li:hover{background: #eee; cursor: pointer;}
.modal-list > li > h3{font-size: 1.6rem;margin-bottom: 5px;}
.modal-list > li > h4{color: #969696;font-weight: 400;}
.modal-list > li > h4 > span{font-size: 1.2rem;}

.modal-btn {margin-top: 15px;margin-bottom: 10px;display: flex;justify-content: center;}
.modal-btn>button.btn-save {background: #0080ff;color: #fff;border: 1px solid #0080ff;}
.modal-btn>button {margin: 0 5px;width: 120px;height: 35px;border: 1px solid #ddd;border-radius: 5px;color: #969696;font-size: 1.4rem;}
</style>
<script>
import moment from "moment";

export default {
  components: {
  },
  name: "Register",
  data() {
    return {
      moment: moment,
      showStationSiteModal: false,
      showStationUserModal: false,
      selectedSite: "",
      siteList: [
        // 예시 데이터
        { name: "현장 1", address: "서울특별시 강남구", affiliation: "테이슨 개발팀" },
        { name: "현장 2", address: "부산광역시 해운대구", affiliation: "테이슨 디자인팀" }
      ],
      selectedUser: {
        name: "",   
        affiliation: ""  
      },
      userList: [
        // 예시 데이터
        { name: "홍길동", email: "asdf123@teixon.com", affiliation: "테이슨 개발팀" },
        { name: "박길동", email: "vfe1256@naver.com", affiliation: "테이슨 디자인팀" }
      ],
    };
  },
  mounted() {

  },
  watch : {
  
  },

  methods: {
    openStationSite() {
        this.showStationSiteModal = true;
    },
    closeStationSiteModal() {
        this.showStationSiteModal = false;
    },
    selectSite(site) {
      // 선택한 현장의 이름을 input 필드에 반영
      this.selectedSite = site.name;
      this.closeStationSiteModal();
    },
    openStationUser() {
        // selectedSite 값이 없으면 알림창을 띄움
      if (!this.selectedSite) {
        alert("먼저 현장을 선택하세요."); // 경고 메시지
      } else {
        // selectedSite가 있을 경우 사용자 모달을 열기
        this.showStationUserModal = true;
      }
    },
    closeStationUserModal() {
        this.showStationUserModal = false;
    },
    selectUser(user) {
      // 선택한 현장의 이름을 input 필드에 반영
      this.selectedUser = { ...user };
      this.closeStationUserModal();
    },

  },
};
</script>